/* ubuntu-mono-regular - latin */
@font-face {
  font-family: "Ubuntu Mono";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/ubuntu-mono-v10-latin/ubuntu-mono-v10-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/ubuntu-mono-v10-latin/ubuntu-mono-v10-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("./fonts/ubuntu-mono-v10-latin/ubuntu-mono-v10-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./fonts/ubuntu-mono-v10-latin/ubuntu-mono-v10-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("./fonts/ubuntu-mono-v10-latin/ubuntu-mono-v10-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/ubuntu-mono-v10-latin/ubuntu-mono-v10-latin-regular.svg#UbuntuMono")
      format("svg"); /* Legacy iOS */
}
/* Roboto */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/Roboto/Roboto-Regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/Roboto/Roboto-Regular.eot?#iefix")
      format("embedded-opentype"),
      /* IE6-IE8  */
    url("./fonts/Roboto/Roboto-Regular.woff2")
      format("woff2"), 
     /* Super Modern Browsers  */
      url("./fonts/Roboto/Roboto-Regular.woff") format("woff"), 
    /* Modern Browsers */ url("./fonts/Roboto/Roboto-Regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/Roboto/Roboto-Regular.svg") format("svg");  /* Legacy iOS */
}

@font-face {
  font-family: "Roboto-Light";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/Roboto/Roboto-Light.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/Roboto/Roboto-Light.eot?#iefix")
      format("embedded-opentype"),
      /* IE6-IE8  */
    url("./fonts/Roboto/Roboto-Light.woff2")
      format("woff2"), 
     /* Super Modern Browsers  */
      url("./fonts/Roboto/Roboto-Light.woff") format("woff"), 
    /* Modern Browsers */ url("./fonts/Roboto/Roboto-Light.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/Roboto/Roboto-Light.svg") format("svg");  /* Legacy iOS */
}

/* roboto-regular - greek_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/roboto-v30-greek_latin/roboto-v30-greek_latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
       url('./fonts/roboto-v30-greek_latin/roboto-v30-greek_latin-regular.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Ubuntu Mono", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
